<script lang='ts'>
  import { onMount } from 'svelte'

  import langs from '@/config/languages'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { hasLevel } from '@/helpers/game'
  import { notifier } from '@/helpers/notifier'

  import WordInfoDoc from '@/components/docs/admin/WordInfoDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import Overlay from '@/components/Overlay.svelte'

  document.title = 'Word info'

  let word = '', language = '1', languages = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,78', like = false
  let words: any = []
  let origWords: any
  let current: any = {}
  let editing = false

  const langsIndexed: any = {}
  langs.forEach((lang) => {
    langsIndexed[lang.id] = lang
  })

  langs.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  const colors: Record<number, string> = {
    0: '#103e00',
    1: '#ff0000',
    2: '#00ff00',
    3: '#0000ff',
    4: '#ffff00',
    5: '#00ffff',
    6: '#ff00ff',
    7: '#ff8000',
    8: '#00ff80',
    9: '#ff0080',
    // eslint-disable-next-line sort-keys
    10: '#80ff00',
    11: '#80ff80',
    12: '#8080ff',
    13: '#ff8080',
    14: '#808080',
    15: '#000000'
  }
  const langColors: any = {}
  let lastColor = 0

  const loadData = () => {
    words = []
    fetchData('mod/wordInfo', { language, languages, like, word }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
        return
      }
      words = data.words
      let prev = ''
      words.forEach((word: any, i: number) => {
        if (!langColors[word.id_language]) {
          langColors[word.id_language] = colors[lastColor++]
        }
        if (prev !== word.id_english_word) {
          words[i].first = true
          prev = word.id_english_word
        } else {
          words[i].first = false
        }
      })
      words.forEach((w: any, k: any) => {
        w.indexOrder = k
      })
      words = words

      origWords = data.origWords
    })
  }

  const addWord = (v: any) => {
    current = { ...v }
    editing = true
  }

  const saveWord = () => {
    fetchData('mod/wordInfoSave', current).then((data) => {
      if (data.error) {
        notifier.error(data.error)
        return
      }
      current.id = data.id
      editing = false
      words.splice(current.indexOrder + 1, 0, current)
      words.forEach((w: any, k: any) => {
        w.indexOrder = k
      })
      words = words
      current = {}
    })
  }

  const closeModal = () => {
    editing = false
  }

  let wordInput: any

  onMount(() => {
    wordInput.focus() // handle autofocus because autofit attribute triggers a warning
  })
</script>

<h1>Word info</h1>

<form action='' method='post' on:submit|preventDefault={loadData}>
  Word: <input bind:this={wordInput} type='text' bind:value={word} name='word' /> like:<input type='checkbox' bind:checked={like} name='like' /><br />
  Lang:<input type='text' bind:value={language} name='lang' /> <br />
  Langs:<input type='text' bind:value={languages} name='langs' /> <br />
  <input type='submit' value='Send' />
</form>

{#if words.length}
  <table class='table'>
    <thead>
    <tr>
      <th>Id</th>
      <th>Word</th>
      <th>Lang</th>
      <th>Freq</th>
      <th>POS</th>
      <th>Audio</th>
    </tr>
    </thead>
    {#each words as v, k}
      {#if v.first}
        <tr>
          <th>{v.id_english_word}</th>
          <td colspan='6' style='background: var(--Base-White)'>{origWords[v.id_english_word].definition}</td>
        </tr>
      {/if}
      <tr>
        <td style:background={langColors[v.id_language]}>{v.id}</td>
        <td>{v.word}</td>
        <td>{langsIndexed[v.id_language].name}</td>
        <td>{v.frequency}</td>
        <td>{poses[v.pos]?.pos || 12}</td>
        <td>{v.audio}</td>
        <td class:-hidden={!hasLevel(8)}><span
          class='link'
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={() => { addWord(v) }}>Duplicate</span></td>
      </tr>
    {/each}
  </table>
{/if}
{#if editing}
  <Overlay hidden={!editing} onClick={closeModal}>
    <form class='word-form' on:submit|preventDefault={saveWord}>
      <p><label for='word'>Word</label><input id='word' type='text' bind:value={current.word} /></p>
      <p><label for='definition'>Definition</label><input id='definition' type='text' bind:value={current.definition} /></p>
      <p><label for='frequency'>Frequency</label><input id='frequency' type='text' bind:value={current.frequency} /></p>
      <p><label for='audio'>Audio</label><input id='audio' type='text' bind:value={current.audio} /></p>
      <div><label for='pos'>POS</label>

        <div class='custom-select'>
          <select id='pos' bind:value={current.pos} name='pos'>
            {#each poses as v, k}
              <option value={k}>{v.pos}</option>
            {/each}
          </select>
        </div>
      </div>
      <div><label for='language'>Language</label>

        <div class='custom-select'>
          <select id='language' bind:value={current.id_language} name='language'>
            {#each langs as v, k}
              <option value={v.id}>{v.name}</option>
            {/each}
          </select>
        </div>
      </div>
      <input type='submit' value='Save' />
    </form>
  </Overlay>
{/if}
<Documentation>
  <WordInfoDoc />
</Documentation>
<style lang='scss'>
  /* stylelint-disable-next-line rscss/class-format */
  .link {
    cursor: pointer;
  }

  .word-form {
    overflow-y: scroll;
    /* stylelint-disable declaration-property-value-allowed-list */
    max-height: 98vh;
    margin: 1vh;
    /* stylelint-enable declaration-property-value-allowed-list */
    padding: 1.4rem;
    background: var(--Base-White);
  }
</style>
