<script lang='ts'>
  import { onMount } from 'svelte';
  import { link, navigate } from 'svelte-routing';

  import languages from "@/config/languages";

  import { fetchData } from '@/helpers/fetchHelpers';
  import { notifier } from '@/helpers/notifier';

  import Breadcrumb from "@/components/Breadcrumb.svelte";
  import IconPencilSimple from "@/components/icons/IconPencilSimple.svelte";
  import IconTrash from "@/components/icons/IconTrash.svelte";

  export let iso = '';
  export let courseId: string;
  export let id: string;

  let module = {
    lessons: [],
    orderIndex: 0,
    title: ''
  };
  const selectedLanguage = languages.filter(el => el.iso === iso)[0].id;

  let moduleForm: HTMLFormElement;

  onMount(async () => {
    if (id !== 'new') {
      await loadModule();
    }
  });

  const loadModule = async () => {
    try {
      const data = await fetchData('modcourse/list', {
        id,
        idLanguage: selectedLanguage,
        moduleId: id
      });
      module = {
        lessons: (data.lessons || []).map(lesson => ({
          content: lesson.content,
          duration: lesson.duration,
          id: lesson.id,
          orderIndex: lesson.order_index,
          title: lesson.title,
          videoUrl: lesson.video_url
        })),
        orderIndex: data.module.order_index,
        title: data.module.title
      };
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to load module');
    }
  };

  const deleteLesson = async (lessonId: string) => {
    if (!confirm('Are you sure you want to delete this lesson?')) return;

    try {
      const response = await fetchData('modcourse/deleteLesson', { id: lessonId });
      if (response.error) {
        notifier.warning(response.error);
      } else {
        notifier.success('Lesson deleted successfully');
        await loadModule();
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to delete lesson');
    }
  };

  const handleSubmit = async (event: Event) => {
    event.preventDefault();
    try {
      const method = id === 'new' ? 'saveModule' : 'updateModule';
      const data = {
        id: id !== 'new' ? id : undefined,
        idCourse: courseId,
        orderIndex: module.orderIndex,
        title: module.title
      }

      const response = await fetchData(`modcourse/${method}`, data);
      if (response.error) {
        notifier.warning(response.error);
      } else {
        notifier.success('Module saved successfully');
        if (id === 'new') {
          navigate(`/${iso}/courses/${courseId}`);
        } else {
          await loadModule();
        }
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to save module');
    }
  };
</script>

<div class="module-management">

  <Breadcrumb
    segments={[
     { href: `/${iso}`, title: iso },
     { href: `/${iso}/courses`, title: 'Courses' },
     { href: `/${iso}/courses/${courseId}`, title: 'Course' },
     { title: id === 'new' ? 'Add Module' : 'Edit Module' }
     ]}
  />
  <div class="header">
    <h1>{id === 'new' ? 'Add New Module' : 'Edit Module'}</h1>
    {#if id !== 'new'}
      <a class="primary" href={`/${iso}/courses/${courseId}/modules/${id}/lessons/new`} use:link>Add Lesson</a>
    {/if}
  </div>

  <div class="content">
    <form bind:this={moduleForm} class="module-info" on:submit={handleSubmit}>
      <div class="form-group">
        <label for="title">Title</label>
        <input id="title" required type="text" bind:value={module.title} name="title" />
      </div>

      <div class="form-group">
        <label for="orderIndex">Order</label>
        <input id="orderIndex" type="number" bind:value={module.orderIndex} name="orderIndex" />
      </div>

      <div class="form-actions">
        <button class="primary" type="submit">{id === 'new' ? 'Create Module' : 'Update Module'}</button>
      </div>
    </form>

    {#if id !== 'new' && module.lessons?.length}
      <div class="lessons">
        <h2>Lessons</h2>
        {#each module.lessons as lesson}
          <div class="lesson-card">
            <div class="lesson-header">
              <h3><span class="order">#{lesson.orderIndex}</span> {lesson.title}</h3>
              <div class="lesson-actions">
                <a href="/{iso}/courses/{courseId}/modules/{id}/lessons/{lesson.id}" use:link><IconPencilSimple /></a>
                <a href='#top' on:click={() => deleteLesson(lesson.id)}><IconTrash /></a>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .module-management {
    padding: 2rem;
  }

  .lesson-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .content {
    display: grid;
    grid-template-columns: minmax(30rem, 40rem) 1fr;
    gap: 2rem;
  }

  .module-info {
    height: fit-content;
    padding: 2rem;
    background: var(--Base-White);
    border-radius: 0.4rem;
  }

  .form-group {
    margin-bottom: 1.4rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 0.5rem;
      border: solid var(--Gray-Light) 0.1rem;
      border-radius: 0.4rem;
    }
  }

  .form-actions {
    display: flex;
    gap: 1rem;
  }

  .lessons {
    h2 {
      margin-bottom: 2rem;
    }
  }

  .lesson-card {
    margin-bottom: 1rem;
    padding: 1.4rem;
    background: var(--Base-White);
    border-radius: 0.4rem;

    .lesson-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
      }
    }
    .order {
      color: var(--Gray-Dark);
    }
  }
</style>
