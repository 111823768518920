<script lang='ts'>

  import languages from '@/config/languages'

  import { API_URL, getCookie } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  export let iso = ''

  document.title = 'Import data ' + iso

  const now = new Date()
  let date = now.getFullYear() + '-' + addZero(now.getMonth()) + '-' + addZero(now.getDate()),
    time = '00:00:00',
    language: number,
    groups: any[] = [],
    data: any = {},
    disableForm = false
  let files: any, type: 'json' | 'csv', uploadLanguage: string, uploadForm: any

  const load = async () => {
    const postData = await fetchData('admin/importData', { date, groups, language, time })
    if (postData.error) {
      notifier.info(postData.error)
    } else {
      data = postData
      disableForm = false
    }
  }

  function addZero(num: number) {
    return num < 10 ? '0' + num : num
  }

  function sendForm() {
    disableForm = true
    load()
  }

  const handleUpload = (event: any) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('csv', files.files[0])
    formData.append('file_upload', 'true')
    formData.append('type', type)
    formData.append('language', uploadLanguage)

    fetch(API_URL + '/admin/uploadData', {
      body: formData,
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + getCookie('jwt')
      },
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          notifier.info(data.error)
        } else {
          notifier.success('File uploaded')
        }
        uploadForm.reset()
      })
      .catch(error => {
        console.error(error)
      })
  }
</script>
{#if disableForm}
  ... Loading
{/if}

<pre>
CSV structure
words:     id, word, definition, pos, translation
lessons:   id_english, english_name, translation
sentences: id, sentence, id_lesson, lesson_name, translation
interface: key, english, translation
</pre>

<p style='color: var(--Error-Medium);'>Don't forget that lessons should be imported first!</p>
<form class='import-data-form' class:-hidden={disableForm} on:submit|preventDefault={sendForm}>
  <div>Language
    <div class='custom-select'>
      <select required bind:value={language}>
        <option value=''>Choose</option>
        {#each languages as lang}
          <option value={lang.id}>{lang.name} ({lang.iso})</option>
        {/each}
      </select>
    </div>
  </div>

  <div class='custom-select'>
    <select
      id='groups'
      multiple
      required
      size='6'
      bind:value={groups}
      name='groups[]'>
      <option value='lessons'>Lessons</option>
      <option value='sentences'>Sentences</option>
      <option value='words'>Words</option>
      <option value='interface'>Interface</option>
    </select>
  </div>
  <label><input id='date' type='date' bind:value={date} name='date' /></label>
  <label><input id='time' type='time' bind:value={time} name='time' /></label>

  <input type='submit' value='Send' name='submit' />
</form>

<form bind:this={uploadForm} action='' on:submit={handleUpload}>
  upload: <input bind:this={files} id='file' type='file' name='file' />
  <div class='custom-select'>
    <select id='type' bind:value={type}>
      <option value='lessons'>Lessons</option>
      <option value='sentences'>Sentences</option>
      <option value='words'>Words</option>
      <option value='interface'>Interface</option>
    </select>
  </div>
  <div class='custom-select'>
    <select required bind:value={uploadLanguage}>
      <option value=''>Choose</option>
      {#each languages as lang}
        <option value={lang.id}>{lang.name} ({lang.iso})</option>
      {/each}
    </select>
  </div>
  <input type='submit' value='Send' />
</form>

{#if data.output}
  {@html data.output}
{/if}
<style lang='scss'>
  .import-data-form {
    &.-hidden {
      display: none;
    }
  }
</style>
