<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import languages from '@/config/languages'

  import { hasLevel } from '@/helpers/game'

  import HomeDoc from '@/components/docs/HomeDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import LiLink from '@/components/LiLink.svelte'

  let iso = ''
  document.title = 'Langoid moderator'
  let loaded = false
  onMount(() => {
    setTimeout(() => {
      loaded = true
    }, 200)
  })

  $: if (iso) {
    navigate(`/${iso}`)
  }
</script>
{#if loaded}
  <div class='home-page'>
    {#if languages.length > 0}
      <div class='custom-select'>
        <select bind:value={iso} name=''>
          <option value=''>Choose language</option>
          {#each languages as lang}
            <option value={lang.iso}>{lang.name}</option>
          {/each}
        </select>
      </div>
    {:else}
      <p>no data to display</p>
    {/if}
    <hr />
    <ul class='crud-block' class:-hidden={!hasLevel(6)}>
      <LiLink to='/docs'>Docs</LiLink>
      <LiLink to='/language/add'>Add languages</LiLink>
      <LiLink to='/admin/unclear-meaning-sentences'>Unclear meaning sentences</LiLink>
      <LiLink to='/mix/moderate-reports'>Moderate reports</LiLink>
      <LiLink to='/tasks-dashboard'>Tasks dashboard</LiLink>
      <LiLink level={5} to='/admin/word-info'>Word info</LiLink>
    </ul>
    <div class='crud-block-wrapper' class:-hidden={!hasLevel(10)}>
      <ul class='crud-block'>
        <LiLink to='/admin/dev/dev-scripts'>Dev scripts</LiLink>
        <LiLink to='/admin/dev/word-info-dev'>Word info dev</LiLink>
        <LiLink to='/admin/word-info'>Word info</LiLink>
        <LiLink to='/admin/dev/word-forms'>Word forms</LiLink>
        <LiLink to='/admin/dev/automatic-post'>Automatic post</LiLink>
        <LiLink to='/admin/dev/import-data'>Import data</LiLink>
        <LiLink to='/admin/dev/cli'>Cli</LiLink>
        <LiLink to='/admin/summary/users'>Users summary</LiLink>
        <LiLink to='/word-groups'>Word groups</LiLink>
        <LiLink to='/add-audio/word'>Add audio word</LiLink>
        <LiLink to='/add-audio/sentence'>Add audio sentence</LiLink>
      </ul>
      <h2>Crud</h2>
      <ul class='crud-block' class:-hidden={!hasLevel(5)}>
        <LiLink to='/crud/languages'>Languages</LiLink>
        <LiLink to='/crud/languages-all'>All Languages</LiLink>
        <LiLink to='/crud/wif-html'>WIF HTML</LiLink>
        <LiLink to='/crud/test-templates'>Test templates</LiLink>
        <LiLink to='/crud/word-property'>Word Properties</LiLink>
        <LiLink to='/crud/word-group'>Word Groups</LiLink>
        <LiLink to='/crud/word-group-type'>Word Groups Types</LiLink>
        <LiLink to='/crud/goal'>Goal</LiLink>
        <LiLink to='/crud/goal-summary'>Goal Summary</LiLink>
        <LiLink to='/crud/chat-bookmarks'>Chat Bookmarks</LiLink>
        <LiLink to='/crud/chat-corrections'>Chat Corrections</LiLink>
        <LiLink to='/crud/chat-messages'>Chat Messages</LiLink>
        <LiLink to='/crud/chat-explanations'>Chat Explanations</LiLink>
        <LiLink to='/crud/chat-post-requests'>Chat Post Requests</LiLink>
        <LiLink to='/crud/payment'>Payment</LiLink>
        <LiLink to='/crud/notifications'>Notifications</LiLink>
        <LiLink to='/crud/game-action'>Game Actions</LiLink>
        <LiLink to='/crud/game-badge'>Game Badges</LiLink>
        <LiLink to='/crud/game-badge-group'>Game Badge Groups</LiLink>
        <LiLink to='/crud/game-badge-template'>Game Badge Templates</LiLink>
        <LiLink to='/crud/game-level'>Game Levels</LiLink>
        <LiLink to='/crud/forum-category'>Forum Category</LiLink>
        <LiLink to='/crud/forum-topic'>Forum Topic</LiLink>
        <LiLink to='/crud/forum-reply'>Forum Reply</LiLink>
        <LiLink to='/crud/forum-translation-category'>Forum Category Translation</LiLink>
        <LiLink to='/crud/forum-translation-topic'>Forum Topic Translation</LiLink>
        <LiLink to='/crud/forum-translation-reply'>Forum Reply Translation</LiLink>
        <LiLink to='/crud/cronjob'>Cronjob</LiLink>
        <LiLink to='/crud/cronjob-log'>Cronjob Log</LiLink>
        <LiLink to='/crud/lessons-sentences'>Lessons Sentences</LiLink>
        <LiLink to='/crud/sentence-examples'>Sentence Examples</LiLink>
        <LiLink to='/crud/sentence-meaning'>Sentence Meaning</LiLink>
        <LiLink to='/crud/sentence-words'>Sentence Words</LiLink>
        <LiLink to='/crud/friends'>Friends</LiLink>
        <LiLink to='/crud/reading-levels'>Reading Levels</LiLink>
        <LiLink to='/crud/reading-questions'>Reading Questions</LiLink>
        <LiLink to='/crud/users'>Users</LiLink>
        <LiLink to='/crud/mod-sentence-audio'>Mod sentence audio</LiLink>
        <LiLink to='/crud/mod-word-audio'>Mod word audio</LiLink>
      </ul>
    </div>
  </div>
{/if}
<Documentation dateCompleted='2023-08-26'>
  <HomeDoc />
</Documentation>
<style lang='scss'>
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90rem;
    margin: 2rem auto 4.8rem;
  }

  .crud-block {
    display: grid;

    &.-hidden {
      display: none;
    }
  }

  .crud-block-wrapper {
    &.-hidden {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .crud-block {
      grid-template-columns: repeat(5,1fr);
      gap: 0.2rem;
    }
  }
</style>
