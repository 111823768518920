<script lang='ts'>

  import Overlay from '@/components/Overlay.svelte'

  export let dateCompleted = ''

  let showing = false
  const closeModal = () => {
    showing = false
  }
  const showDoc = () => {
    showing = true
  }
</script>
<button
  class='open-doc'
  class:-completed={dateCompleted !== ''}
  title={dateCompleted}
  type='button'
  on:click={showDoc}>?</button>
{#if showing}
  <Overlay hidden={!showing} onClick={closeModal}>
    <div class='overlay-content'>
      <slot />
    </div>
  </Overlay>
{/if}
<style lang='scss'>
.open-doc {
  position: fixed;
  right: 0.2rem;
  bottom: 0.2rem;
  display: inline-block;
  min-width: 0;
  padding: 0.5rem 1rem;
  color: var(--Base-White);
  background: var(--Error-Medium);
  border-radius: 50%;

  &.-completed {
    background: var(--Primary-Ligth);
  }
}

.overlay-content {
  max-width: 120rem;
}
</style>
