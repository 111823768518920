<script lang='ts'>
  import { getIdFromIso } from '@/helpers/apiCall'
  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { capitalize } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  import WifCheckDoc from '@/components/docs/admin/WifCheckDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'WIF Check'

  export let iso = ''
  const lang = getIdFromIso(iso)
  let pos = 0
  let user: any
  let rules: any = {}
  let templates: any = []
  let wifs: any = {}

  let info: any = []
  let infoStatus = {
    fail: 0,
    failedForms: 0,
    pass: 0,
    passedForms: 0,
    status: '',
    words: 0
  }

  let invalidTypesGrouping = 'words' // | wifs

  const handleSubmit = () => {
    const requestData = { lang, pos, user }
    fetchData('mod/wifCheck', requestData).then((data) => {
      rules = data.rules
      templates = data.templates
      wifs = data.wifs
      infoStatus = {
        fail: 0,
        failedForms: 0,
        pass: 0,
        passedForms: 0,
        status: '',
        words: 0
      }
      info = []
      if (data.error) {
        notifier.error(data.error)
      } else {
        // e.target.reset() // todo - uncomment
        if (data.words.length === 0) {
          notifier.error('No words found')
          return
        }
        for (const wordId in data.words) {
          const forms = data.words[wordId]
          const tmpInfo: any = {}
          for (let i = 0; i < forms.length; i++) {
            const userWord = forms[i].content
            const wifTemplate = forms[i].wif_template
            const idWifTemplate = forms[i].id_wif_template
            const generatedWord = data.generated[wordId][1][wifTemplate]
            const isSame = wifs[`${wordId}-${idWifTemplate}`]
              ? wifs[`${wordId}-${idWifTemplate}`] === generatedWord
              : (forms[i].content === generatedWord) || (forms[i].content === '-' && generatedWord === '')
            if (!isSame) {
              // todo - we fixed here probably wrong join comming from server
              tmpInfo[wifTemplate] = { generatedWord, idWifTemplate, userWord: wifs[`${wordId}-${idWifTemplate}`] || userWord, wifTemplate }
              infoStatus.failedForms++
            } else {
              infoStatus.passedForms++
            }
          }
          const tmpInfo2 = Object.values(tmpInfo)
          const method = tmpInfo2.length === 0 ? 'push' : 'unshift' // put failed on top
          info[method]({ forms: tmpInfo2, word: forms[0]?.word, wordId })
          infoStatus[tmpInfo2.length === 0 ? 'pass' : 'fail']++
        }
        infoStatus.words = Object.keys(data.words).length || 0
        infoStatus.status = infoStatus.fail ? 'fail' : 'pass'
        notifier.success('Saved!')
      }
    })
  }

  const saveWifForms = () => {
    if (!confirm('Are you sure? This will save user data to production database.')) {
      return
    }
    const requestData = { lang, pos, user }
    fetchData('mod/wifCopyUnitForms', requestData).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        notifier.success('Saved')
      }
    })
  }
</script>
<form action='' on:submit|preventDefault={handleSubmit}>
  <p><label for='user'>User id</label> <input id='user' required type='text' bind:value={user} /></p>
  <div><label for='pos'>POS</label>

    <div class='custom-select'>
      <select id='pos' required bind:value={pos} name=''>
        <option value=''>Select pos</option>
        {#each poses as pos}
          <option value={pos.id}>{pos.pos}</option>
        {/each}
      </select>
    </div>
  </div>
  <input type='submit' value='Save' />
</form>
{#if info.length}
  <div>
    <h1 class={infoStatus.status}>{infoStatus.status}</h1>
    <table>
      <tr>
        <td>Words: {infoStatus.words}</td>
        <td class='pass'>PASS: {infoStatus.pass}</td>
        <td class='fail'>FAIL: {infoStatus.fail}</td>
      </tr>
      <tr>
        <td>Forms: {infoStatus.passedForms + infoStatus.failedForms}</td>
        <td class='pass'>PASS: {infoStatus.passedForms}</td>
        <td class='fail'>FAIL: {infoStatus.failedForms}</td>
      </tr>
    </table>
    <input type='button' value='Save values' on:click={saveWifForms} />
  </div>
  {#if infoStatus.fail > 0}
    <button
      type='button'
      on:click={() => {
        invalidTypesGrouping = invalidTypesGrouping === 'words' ? 'wifs' : 'words'
      }}>Regroup by {invalidTypesGrouping === 'words' ? 'wifs' : 'words'}</button>
  {/if}
  {#if invalidTypesGrouping === 'words'}
    {#each info as wordInfo}
      <div>
        {#if wordInfo.forms.length}
          <span class='fail'>FAIL: {wordInfo.wordId} {wordInfo.word}
            <span style='color: var(--Gray-Darker)'>{rules?.[wordInfo.wordId]?.id} {rules?.[wordInfo.wordId]?.name}</span>
          </span>
          {#each wordInfo.forms as form}
            <div>
              <span>{form.idWifTemplate} </span>
              <span>{form.wifTemplate?.split('_').slice(1).map(capitalize).join(' ')}</span>
              <span class='pass'>{form.userWord}</span>
              <span class='fail'>{form.generatedWord}</span>
            </div>
          {/each}
          <hr />
        {:else}
          <span class='pass'>PASS: {wordInfo.wordId} {wordInfo.word}</span>
        {/if}
      </div>
    {/each}
  {:else}
    {#each templates as template}
      <div><strong>{template.id} {template.content?.split('_').slice(1).map(capitalize).join(' ')}</strong></div>
      <table class='table'>
        {#each info as wordInfo}
          {#each wordInfo.forms as form}
            {#if form.wifTemplate === template.content}
              <tr>
                <td>{wordInfo.word}</td>
                <td class='pass'>{form.userWord}</td>
                <td class='fail'>{form.generatedWord}</td>
              </tr>
            {/if}
          {/each}
        {/each}
      </table>
    {/each}
  {/if}
{/if}
<Documentation>
  <WifCheckDoc />
</Documentation>
<style lang='scss'>
  h1 {
    text-transform: uppercase;
  }

  /* stylelint-disable rscss/class-format */
  .fail {
    color: var(--Error-Medium) !important;
  }

  .pass {
    color: var(--Success-Dark) !important;
  }

  /* stylelint-enable rscss/class-format */

  table:empty {
    display: none;
  }
</style>
