<script>
  import { onMount } from 'svelte'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'

  import WordGroupsDoc from '@/components/docs/WordGroupsDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  export let iso = ''
  const idLanguage = getIdFromIso(iso) || 0

  let groups = {}
  let currentTab = 'view'

  // eslint-disable-next-line camelcase
  const newGroupTemplate = { description: '', id: '', id_pos: '', name: '', types: [] }

  let newGroup = { ...newGroupTemplate }

  function generateRandomId () {
    return 'a' + Math.random().toString(36).substring(2, 9)
  }

  async function handleSave () {
    fetchData('mod/wordGroupsSave', { groups, idLanguage }).then(data => {
      groups = data.groups
    })
  }

  async function handleDeleteGroup (groupId) {
    delete groups[groupId]
    groups = groups
  }

  async function handleDeleteType (groupId, typeId) {
    const group = groups[groupId]
    if (!group) return

    const idx = group.types.findIndex(type => type.id === typeId)
    if (idx > -1) group.types.splice(idx, 1)

    groups = groups
  }

  function handleAddGroup () {
    const newGroupId = generateRandomId()
    if (newGroupId) {
      newGroup.id = newGroupId
      groups[newGroupId] = { ...newGroup }
      newGroup = { ...newGroupTemplate }
    }
  }

  function handleAddType (groupId) {
    const group = groups[groupId]
    if (!group) return

    if (!group.types) {
      group.types = []
    }

    group.types.push({ description: '', id: generateRandomId(), name: '' })

    groups = groups
  }

  onMount(() => {
    const idLanguage = getIdFromIso(iso) || 0
    fetchData('mod/wordGroupsLoad', { idLanguage }).then(data => {
      groups = data.groups
    })
  })

</script>
<div>
  <button type='button' on:click={() => { currentTab = 'view' }}>View</button>
  <button type='button' on:click={() => { currentTab = 'edit' }}>Edit</button>

  <div class='tab-content' class:-active={currentTab === 'view'}>
    {#each Object.values(groups) as group (group.id)}
      <h2><a href={`/${iso ? iso + '/' : ''}word-props/${group.id}`}>{group.name} <small>{group.description}</small></a></h2>
      {#if group.types}
        {#each group.types as type (type.id)}
          <div>{type.name} - {type.description}</div>
        {/each}
      {/if}
    {/each}
  </div>

  <div class='tab-content' class:-active={currentTab === 'edit'}>
    {#each Object.values(groups) as group (group.id)}
      <div class='group-block'>
        <input type='text' bind:value={group.name} />
        <small><input type='text' bind:value={group.description} /></small>
        POS: <input type='text' bind:value={group.id_pos} />
        <button type='button' on:click={() => handleDeleteGroup(group.id)}>Delete group</button>
      </div>
      {#if group.types}
        {#each group.types as type (type.id)}
          <div class='group-types'>
            <strong>Name:</strong> <input type='text' bind:value={type.name} />
            <strong>Description:</strong> <input type='text' bind:value={type.description} />
            <button class='deleteType' type='button' on:click={() => handleDeleteType(group.id, type.id)}><img alt='Delete' src='https://cdn.langoid.com/static/images/delete.png' /></button>
          </div>
        {/each}
      {/if}
      <button type='button' on:click={() => handleAddType(group.id)}>Add type</button>
    {/each}
    <h2>Add new group</h2>
    <input placeholder='name' type='text' bind:value={newGroup.name} />
    <button type='button' on:click={handleAddGroup}>Add group</button>

    <button type='button' on:click={() => handleSave()}>Save all groups</button>

  </div>
</div>
<Documentation>
  <WordGroupsDoc />
</Documentation>
<style>
  .tab-content {
    display: none;

    &.-active {
      display: block;
    }
  }

  .group-block {
    margin-top: 2rem;
    background: var(--Primary-Ligther);
  }

  button {
    margin: 0;
    padding: 0.4rem;
  }

  h2 {
    color: var(--Gray-Dark);
  }

  input[type='text'] {
    margin: 0.5em;
    padding: 0.5em;
    border: solid var(--Gray-Medium) 0.1rem;
    border-radius: 0.4rem;
  }

  .group-types > .deleteType {
    width: auto;
    min-width: auto;
    background: none;
    border: none;
    appearance: none;
  }
</style>
