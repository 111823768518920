<script lang='ts'>
  import { onMount } from 'svelte'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { capitalize } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'
  import Code from '@/components/Code.svelte'
  import RecursiveRow from '@/components/RecursiveRow.svelte'

  export let iso = ''
  export let ruleId = 0

  document.title = 'Grammar rules ' + iso
  let rules: any = {}
  let tree: any = []
  let ruleSelected = false
  let rule: any = {}
  let types: any = {}
  const trans: any = {}
  let showOutline = false
  let currentRule: any = {
    type: 0
  }

  const loadRules = () => {
    fetchData('mod/grammarRulesList', {}).then((data) => {
      rules = data.rules
      tree = data.tree
      types = data.types

      if (ruleId) {
        loadRule(rules[ruleId])
        ruleSelected = true
      }
    })
  }
  const loadRule = (loadedRule: any) => {
    if (!loadedRule) return ''
    const id = loadedRule.id
    document.title = `Grammar rules ${iso} : ${id} ${loadedRule.name}`
    if (id !== ruleId) {
      window.history.pushState({}, document.title, `/${iso}/admin/grammar/rules/${id}`)
    }
    if (id === rule.id) { // cache if same rule called again
      return
    }
    fetchData('mod/grammarRule', { id }).then((data) => {
      rule = { ...loadedRule, ...data.rule }
      if (Array.isArray(rule.results)) {
        const tmp: Record<any, any> = {}
        rule.results.forEach((r: any) => {
          if (r?.id) tmp[r.id] = r
        })
        rule.results = tmp
      }

      for (const r in data.rule.wifTemplates) {
        if (!rule.results) {
          rule.results = {}
        }
        if (!rule.results[r]) {
          const wif = data.rule.wifTemplates[r]
          rule.results[r] = { id: r, rule: '', wif }
        }
      }
      rule.conditions = Object.values(rule.conditions || {}) || []
    })
  }

  loadRules()

  const selectRule = (event: CustomEvent) => {
    loadRule(event.detail)
    ruleSelected = true
  }

  const ch = (e: any, id: any) => {
    rule.results[id].rule = e.currentTarget.value
    rule = rule
  }

  const saveMiddleRule = () => {
    if (currentRule.type === 0) {
      notifier.error('Please select a type')
      return
    }
    const relDiv: HTMLSelectElement | HTMLTextAreaElement | null = document.querySelector(`#condition_type_${currentRule.type} .relControl`)
    if (!relDiv) {
      // console.log('no rel div' + currentRule.type)
      return
    } else {
      currentRule.rel = relDiv.value
    }
    if (currentRule.index !== undefined) {
      rule.conditions[currentRule.index] = currentRule
    } else {
      rule.conditions.push(currentRule)
    }

    rule = rule
    currentRule = { type: 0 }
    document.querySelectorAll('.relControl').forEach((el: any) => {
      el.value = ''
    })
  }

  const saveRule = () => {
    Object.keys(rule.results).forEach(r => { // delete already deleted rules from the database
      if (rule.results[r] && !rule.wifTemplates[r]) {
        delete rule.results[r]
      }
    })
    rule = rule
    fetchData('mod/saveGrammarRule', rule)
      .then((data) => {
        if (data.error) {
          notifier.error(data.error)
        } else {
          notifier.info('Saved')
        }
      }).catch((e) => notifier.error(e.message))
  }

  const changeOperationStart = (e: any) => {
    rule.operation_start = e.currentTarget.value
  }

  const relFormat = (cond: any) => {
    if (cond.type === 1) {
      return poses[cond.rel].pos
    }
    return cond.rel
  }

  onMount(() => {
    const handlePopState = () => {
      const last = parseInt(location.pathname.split('/').pop() || '')
      if (last > 0 && last !== ruleId) {
        loadRule(rules[last])
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  })
  const getRelDiv = (type: string) => {
    return document.querySelector(`#condition_type_${type} .relControl`) as HTMLInputElement | HTMLTextAreaElement | null
  }
</script>
<Breadcrumb />
<div id='top' />
{#if ruleSelected}
  <div
    role='button'
    tabindex='0'
    on:keypress={() => {}}
    on:click={() => {
      ruleSelected = false
      window.history.pushState({}, document.title, `/${iso}/admin/grammar/rules`)
    }}>X
  </div> <!-- todo - make component for close button, or shared css  -->
  <h2>{rule.id}. {rule.name}</h2>
  <p>{rule.description || ''}</p>
  <span>Type: <strong>{rule.type}</strong></span>
  <span>{rule.id_pos ? `POS:${rule.id_pos}` : ''}</span> <span>ID Parent: {rule.id_parent}</span>

  <hr />
  <h2>Add conditions</h2>
  <div id='conditions_wrap'>
    <table class='conditionsTable'>
      <tr>
        <td><p class='table-paragraph'><label for='condition_name'>Name</label>
          <input id='condition_name' type='text' bind:value={currentRule.name} /></p></td>
        <td><p class='table-paragraph'><label for='condition_description'>Description</label>
          <textarea id='condition_description' cols='30' rows='4' bind:value={currentRule.description} /></p></td>
        <td>
          <div class='table-paragraph'><label for='condition_type'>Type</label>
            <div class='custom-select'>
              <select id='condition_type' bind:value={currentRule.type}>
                {#each Object.keys(types.grammarTypeConditions) as key}
                  <option value={key}>{types.grammarTypeConditions[key]}</option>
                {/each}
              </select>
            </div>
          </div>
        </td>
        <td>
          <p class='table-paragraph'><label for='condition_operations'>Operations:</label>
            <input id='condition_operations' type='text' bind:value={currentRule.operations} /></p></td>
      </tr>
    </table>

    <div id='condition_typeovi'>
      <div
        id='condition_type_1'
        class='condition-type'
        class:-hidden={currentRule.type !== '1'}>
        <label class='uputstvo' for='pos_condition'>Select part of speech for this rule: &nbsp;</label>
        <div class='custom-select'>
          <select id='pos_condition' class='relControl'>
            {#each Object.keys(types.pos) as key}
              <option value={key}>{types.pos[key]}</option>
            {/each}
          </select>
        </div>
      </div>
      <div
        id='condition_type_3'
        class='condition-type'
        class:-hidden={currentRule.type !== '3'}>
        <label
          for='nastavci_condition_type3'
          style='display: block;clear: both;width: 90%;'>Write list of endings separated by comas</label><br />
        <textarea id='nastavci_condition_type3' class='relControl' cols='100' rows='5' />
      </div>

      <div
        id='condition_type_5'
        class='condition-type'
        class:-hidden={currentRule.type !== '5'}>
        <label
          class='uputstvo'
          for='reci_u_spisku'
          style='display: block;clear: both;width: 90%;'>Write list of words separated by comas</label>
        <textarea class='relControl' cols='100' rows='5' />
      </div>

      <div
        id='condition_type_8'
        class='condition-type'
        class:-hidden={currentRule.type !== '8'}>
        <p class='uputstvo' />
        <!-- todo - uslovno_pravilo select_rulesList('uslovno_pravilo', ' id="uslovno_pravilo" class="relControl"', ''); -->

        <div class='custom-select'>
          <select class='relControl'>
            <option value=''>Choose</option>
            {#each Object.keys(types.grammar_rules) as key}
              <option value={key}>{types.grammar_rules[key]}</option>
            {/each}
          </select>
        </div>
      </div>
      <div
        id='condition_type_11'
        class='condition-type'
        class:-hidden={currentRule.type !== '11'}>
        <p class='uputstvo'>Will be transformed</p>
        <textarea class='relControl' cols='100' rows='5' style='width: 100%' />
      </div>
    </div>
    <input type='button' value='Save condition' on:keypress={() => {}} on:click={saveMiddleRule} />
    {#if currentRule.index !== undefined}
      <input
        type='button'
        value='Reset'
        on:keypress={() => {}}
        on:click={() => {
          currentRule = { type: 0 }
        }} />
    {/if}

    <p><label>Operations start :<input id='operation_start' type='text' value={rule.operation_start} on:change={changeOperationStart} /></label></p>

    <table id='dodati_conditions' class='table'>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Type</th>
        <th>Relation</th>
        <th>Operations</th>
        <th>Edit</th>
        <th>Delete</th>
        <th>Up</th>
        <th>Down</th>
      </tr>
      {#if rule.conditions}
        {#each rule.conditions as condition, k}
          <tr class='red'>
            <td class='td_name'>{condition.name || ''}</td>
            <td class='td_description'>{condition.description || ''}</td>
            <td class='td_type'>{types.grammarTypeConditions[condition.type] || ''}</td>
            <td class='td_rel'>{relFormat(condition)}</td>
            <td class='td_operations'>{condition.operations || ''}</td>

            <td
              class='edit_condition'
              on:keypress={() => {}}
              on:click={() => {
                currentRule = condition
                currentRule.type = condition.type
                // todo - relDiv is not div
                const relDiv = getRelDiv(condition.type)
                if (relDiv) {
                  relDiv.value = condition.rel
                }
                currentRule.index = k
              }}>Edit
            </td>

            <td
              class='delete_condition'
              on:keypress={() => {}}
              on:click={() => {
                rule.conditions.splice(k, 1)
                rule.conditions = rule.conditions
              }}>Delete
            </td>

            <td
              on:keypress={() => {}}
              on:click={() => {
                if (k > 0) {
                  const tmp = rule.conditions[k]
                  rule.conditions[k] = rule.conditions[k - 1]
                  rule.conditions[k - 1] = tmp
                  rule.conditions = rule.conditions
                }
              }}>{k > 0 ? 'Up' : ''}</td>

            <td
              on:keypress={() => {}}
              on:click={() => {
                if (k < rule.conditions.length - 1) {
                  const tmp = rule.conditions[k]
                  rule.conditions[k] = rule.conditions[k + 1]
                  rule.conditions[k + 1] = tmp
                  rule.conditions = rule.conditions
                }
              }}>{k < rule.conditions.length - 1 ? 'Down' : ''}</td>
          </tr>
        {/each}
      {/if}
    </table>
  </div>

  <hr />
  <h2>Add transformation</h2>

  <div class='outline-block' class:-hidden={!showOutline}>
    <div
      class='close'
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => { showOutline = false }}>X
    </div>
    {#each Object.keys(rule.wifTemplates || {}) as id}<a
      href='#wif_{id}'>{rule.wifTemplates[id].split('_').slice(1).map(capitalize).join(' ')}</a><br />{/each}
  </div>
  {#if rule.orders}
    {#each rule.orders as item}
      {@const code = rule?.results?.[item.id].rule || ''}
      <div id='wif_{item.id}' class='red-results {rule.wifTemplates[item.id].split(/_/)[0]}'>
        <p>
          <strong>{item.id}</strong>
          {rule.wifTemplates[item.id].split('_').slice(1).map(capitalize).join(' ')}</p>
        <textarea bind:this={trans[item.id]} cols='50' rows='2' on:keyup={(e) => ch(e, item.id)}>{code}</textarea>
        <Code code={trans[item.id]?.value || '|'} />
      </div>
    {/each}
  {/if}

  <div class='main-save'>
    <div
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => { showOutline = true }}>Show outline
    </div>&nbsp;&nbsp;&nbsp;
    <a href='#top'>Go to top</a>&nbsp;&nbsp;&nbsp;
    <input type='button' value='Save rule' on:keypress={() => {}} on:click={saveRule} />
  </div>

{:else}
  {#if rule.id}
    <span
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => { ruleSelected = true }}>&gt;&gt; Rule {rule.id} {rule.name}</span>
  {/if}
  {#each tree as rule}
    {#if ![7, 20].includes(rule.id)} <!-- Exclude grupisanje i teorija rules -->
      <RecursiveRow {rule} on:keypress={() => {}} on:click={selectRule} />
    {/if}
  {/each}
{/if}
<style lang='scss'>
  p {
    margin: 0 !important;
  }

  .condition-type {
    &.-hidden {
      display: none;
    }
  }

  .table-paragraph {
    margin: 0;

    > input,
    > textarea {
      width: auto;
      min-width: 4.8rem;
    }

    > textarea {
      position: relative;
      top: 0.2rem;
      height: 4.8rem;
    }
  }

  .main-save {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    padding: 0.4rem;
    background: var(--Base-White);
  }

  .red-results {
    padding: 1rem;
    background-color: var(--Base-White);

    &:nth-child(odd) {
      background-color: var(--Gray-Medium);
    }
  }

  .outline-block {
    &.-hidden {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .outline-block {
      position: fixed;
      right: 0;
      bottom: 4.8rem;
      z-index: 1;
      overflow: scroll;
      max-height: calc(100vh - 4.8rem);
      padding: 1rem;
      background: var(--Base-White);
    }
  }
</style>
