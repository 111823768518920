<script lang='ts'>

  import languages from '@/config/languages'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import PrintTable from '@/components/PrintTable.svelte'

  export let iso = ''

  document.title = 'Word info ' + iso

  let learningId: number,
    interfaceId: number
  const words: any[] = []
  let word = ''
  let htmlData: any = {}
  const load = async (params: any = {}) => {
    const data = await fetchData('admin/wordInfo', {
      interface: params.interface || interfaceId,
      learning: params.learning || learningId,
      word: params.word || word,
      wordId: params.wordId
    })
    if (data.error) {
      notifier.info(data.error)
    } else {
      htmlData = data.htmlData
    }
  }
</script>

<div>{words.length} words</div>
<form class='word-info-form' action='' method='POST' on:submit|preventDefault={load}>
  <label for='learning'>Learning:</label>
  <div class='custom-select'>
    <select id='learning' required bind:value={learningId} name='learning'>
      <option value=''>Choose</option>
      {#each languages as lang}
        <option value={lang.id}>{lang.name} ({lang.iso})</option>
      {/each}
    </select>
  </div>
  <label for='interface'>Interface:</label>

  <div class='custom-select'>
    <select id='interface' required bind:value={interfaceId} name='interface'>
      <option value=''>Choose</option>
      {#each languages as lang}
        <option value={lang.id}>{lang.name} ({lang.iso})</option>
      {/each}
    </select>
  </div>
  {#if learningId && interfaceId}
    <p>
      <label>Word: <input type='text' bind:value={word} name='word' />
        <input type='checkbox' name='like' /></label></p>
    <input type='submit' value='Send' />
  {/if}
</form>

{#if htmlData.wordTable}
  <table>
    {#each htmlData.wordTable as row}
      <tr>
        <td>{row.id}</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => { load({ wordId: row.id }) }}>{row.word}</td>

        <td
          class='clickable-cell'
          on:keypress={() => {}}
          on:click={() => { load({ interface: 1, learning: interfaceId, wordId: row.id_english_word }) }}>{row.eng_word}</td>

        <td
          class='clickable-cell'
          on:keypress={() => {}}
          on:click={() => { load({ interface: interfaceId, learning: 1, wordId: row.i_id }) }}>{row.i_word}</td>
        <td>{poses[row.pos].pos}</td>
        <td>{row.frequency}</td>
        <td>{row.level}</td>
        <td>{row.definition}</td>
        <td>{row.used}</td>
      </tr>
    {/each}
  </table>
{/if}
{#if htmlData.nav}
  <h2>Words</h2>
  <table class='table'>
    {#each htmlData.nav as row}
      <tr>
        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ word: row.word })}>{row.word}</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ id: row.id })}>#</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ language: 1, word: row.e_word })}>{row.e_word}</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ id: row.e_id, language: 1 })}>#</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ language: interfaceId, word: row.i_word })}>{row.i_word}</td>

        <td class='clickable-cell' on:keypress={() => {}} on:click={() => load({ id: row.i_id, language: interfaceId })}>#</td>

        <td>{row.frequency}</td>
        <td>{row.pos}</td>
        <td>{row.e_pos}</td>
        <td>{row.i_pos}</td>
        <td>{row.definition}</td>
        <td />
      </tr>
    {/each}
  </table>
{/if}

{#if htmlData.details}
  {#each htmlData.details as data}
    <h2>{data.word.id} {data.word.word}</h2>
    <h3>Synonyms</h3>
    {@html data.synonyms}
    <h3>Homonyms</h3>
    {@html data.homonyms}
    <h3>Test</h3>
    <PrintTable data={data.test} />
    <h3>Sentences</h3>
    <PrintTable data={data.sentences} />
    <hr />
  {/each}
{/if}
<style>
  .word-info-form {
    padding: 0.2rem 1rem;
    background: var(--Gray-Medium);
    border: solid var(--Gray-Darker) 0.1rem;
  }

  .clickable-cell {
    font-weight: bold;
    cursor: pointer;
  }
</style>
