<script lang='ts'>
  /* eslint-disable camelcase */
  import { onMount } from 'svelte';
  import { link, navigate } from 'svelte-routing';

  import languages from '@/config/languages';

  import { API_URL, getCookie } from '@/helpers/apiCall';
  import { fetchData } from '@/helpers/fetchHelpers';
  import { notifier } from '@/helpers/notifier';

  import Breadcrumb from "@/components/Breadcrumb.svelte";
  import IconPencilSimple from "@/components/icons/IconPencilSimple.svelte";
  import IconPlus from "@/components/icons/IconPlus.svelte";
  import IconTrash from "@/components/icons/IconTrash.svelte";

  export let iso = '';
  export let id: string;

  let course = {
    description: '',
    intro_video_url: '',
    is_published: 'no',
    modules: [],
    order_index: 0,
    price: 0,
    thumbnail_url: '',
    title: ''
  };

  const selectedLanguage = languages.filter(el => el.iso === iso)[0].id;
  let courseForm: HTMLFormElement;
  let imageFile: HTMLInputElement;

  onMount(async () => {
    if (id !== 'new') {
      await loadCourse();
    }
  });

  const loadCourse = async () => {
    try {
      const data = await fetchData('modcourse/list', {
        id,
        idLanguage: selectedLanguage
      });
      course = data.course;
      course.modules = data.modules || [];
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to load course');
    }
  };

  const handleSubmit = async (event: Event) => {
    event.preventDefault();
    try {
      const formData = new FormData(courseForm);
      const method = id === 'new' ? 'save' : 'update';
      formData.append('id_language', selectedLanguage.toString());
      formData.append('file_upload', 'true');

      if (imageFile?.files?.length) {
        formData.append('image', imageFile.files[0]);
      }

      if (id !== 'new') {
        formData.append('id', id);
      }

      const response = await fetch(`${API_URL}/modcourse/${method}`, {
        body: formData,
        credentials: 'include',
        headers: {
          Authorization: 'Bearer ' + getCookie('jwt')
        },
        method: 'POST'
      });

      const data = await response.json();
      if (data.error) {
        notifier.warning(data.error);
      } else {
        notifier.success('Course saved successfully');
        if (id === 'new') {
          navigate(`/${iso}/courses`)
        } else {
          await loadCourse();
        }
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to save course');
    }
  };

  const deleteModule = async (moduleId: string) => {
    if (!confirm('Are you sure you want to delete this module and all its lessons?')) return;

    try {
      const response = await fetchData('modcourse/deleteModule', { id: moduleId });
      if (response.error) {
        notifier.warning(response.error);
      } else {
        notifier.success('Module deleted successfully');
        await loadCourse();
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to delete module');
    }
  };

  const addModule = () => {
    navigate(`/${iso}/courses/${id}/modules/new`)
  };
</script>

<div class="course-management">
  <Breadcrumb
    segments={[
     { href: `/${iso}`, title: iso },
     { href: `/${iso}/courses`, title: 'Courses' },
     { title: 'Course'}
     ]} />
  <div class="header">
    <h1>{id === 'new' ? 'Add New Course' : 'Edit Course'}</h1>
    {#if id !== 'new'}
      <button class="primary" type="button" on:click={addModule}>Add Module</button>
    {/if}
  </div>

  <div class="content">
    <!-- Course Info -->
    <form bind:this={courseForm} class="course-info" on:submit={handleSubmit}>
      <div class="form-group">
        <label for="title">Title</label>
        <input id="title" required type="text" value={course.title} name="title" />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <textarea id="description" required name="description">{course.description}</textarea>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="price">Price</label>
          <input id="price"
                 required
                 step="0.01"
                 type="number"
                 value={course.price}
                 name="price" /></div>

        <div class="form-group">
          <label for="order_index">Order</label>
          <input id="order_index" type="number" value={course.order_index} name="order_index" />
        </div>
      </div>

      <div class="form-group">
        <label for="intro_video_url">Intro Video URL</label>
        <input id="intro_video_url" type="url" value={course.intro_video_url} name="intro_video_url" />
      </div>

      <div class="form-group">
        <label for="image">Course Thumbnail</label>
        <input bind:this={imageFile} id="image" accept="image/*" type="file" />
        {#if course.thumbnail_url}
          <img class="thumbnail" alt="Course thumbnail" src={course.thumbnail_url} />
        {/if}
      </div>

      <div class="form-group">
        <label class="checkbox">
          <input checked={course.is_published === 'yes'} type="checkbox" value="yes" name="is_published" />
          Published
        </label>
      </div>

      <div class="form-actions">
        <button class="primary" type="submit">{id === 'new' ? 'Create Course' : 'Update Course'}</button>
      </div>
    </form>

    <!-- Modules List -->
    {#if id !== 'new' && course.modules?.length}
      <div class="modules">
        {#each course.modules as module}
          <div class="module-card">
            <div class="module-header">
              <h3 title="#{module.id} Order:{module.order_index}"><span class="order">#{module.order_index}</span> <a href="/{iso}/courses/{id}/modules/{module.id}" use:link>{module.title}</a>
              </h3>
              <div class="actions">
                <a href="/{iso}/courses/{id}/modules/{module.id}" use:link><IconPencilSimple /></a>
                <a href='#top' on:click={() => deleteModule(module.id)}><IconTrash /></a>
              </div>
            </div>
            {#if module.description}
              <p>{module.description}</p>
            {/if}
            <div class="lessons">
              <h4>Lessons ({module.lessons?.length || 0})</h4>
              {#each module.lessons as lesson}
                <div class="lesson-card">
                    <a href="/{iso}/courses/{id}/modules/{module.id}/lessons/{lesson.id}" use:link><IconPencilSimple size='16' /></a>
                  <span> {lesson.title}</span>
                </div>
              {/each}
              <a href="/{iso}/courses/{id}/modules/{module.id}/lessons/new" use:link><IconPlus weight="regular" /></a>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .course-management {
    padding: 2rem;
  }

  .actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .content {
    display: grid;
    grid-template-columns: minmax(30rem, 40rem) 1fr;
    gap: 2rem;
  }

  .course-info {
    height: fit-content;
    padding: 2rem;
    background: var(--Base-White);
    border-radius: 0.4rem;
  }

  .form-group {
    margin-bottom: 1.4rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    input[type="text"],
    input[type="number"],
    input[type="url"],
    textarea {
      width: 100%;
      padding: 0.5rem;
      border: solid var(--Gray-Light) 0.1rem;
      border-radius: 0.4rem;
    }

    textarea {
      min-height: 10rem;
    }
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .thumbnail {
    max-width: 19.2rem;
    margin-top: 1rem;
  }

  .modules {
    display: grid;
    gap: 1rem;
    max-width: 76.8rem;
  }

  .module-card {
    padding: 1.4rem;
    background: var(--Base-White);
    border-radius: 0.4rem;

    .module-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      h3 {
        margin: 0;

        .order {
          font-size: 0.6em;
          color: var(--Gray-Dark);
        }
      }
    }

    .lessons {
      margin-top: 1.4rem;
      padding-top: 1.4rem;
      border-top: solid var(--Gray-Light) 0.1rem;
    }
  }

  .lesson-card {
    display: flex;
    gap: 1rem;
  }
</style>
