<script lang='ts'>
  export let content = ''
  export let limit = 255
  let showMore = true
</script>
{#if content?.length > limit && !content.includes('</') && !content.includes('<img')}
  {showMore ? content.substring(0, limit - 10) + '...' : content}
  <div
    class='show-more'
    role='button'
    tabindex='0'
    on:keypress={() => {}}
    on:click={() => { showMore = !showMore }}>Show {showMore ? 'more' : 'less'}</div>
{:else}
  {@html content}
{/if}
<style>
  .show-more {
    font-size: 0.8em;
    color: var(--Primary-Medium);
    cursor: pointer;
  }

  .show-more:hover {
    color: var(--Primary-Medium);
  }
</style>
