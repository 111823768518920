<script lang='ts'>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'

  export let iso = '';
  export let courseId: string;
  export let moduleId: string;
  export let id: string;

  const options: any = {}
  let quill:any
  let isShort = false

  type LessonType = 0|1|2|3|4|5|6|7|8|9
  /* eslint-disable */
  const LESSON_TYPES: Record<string, LessonType> = {
    TEXT: 0,
    WORD_TESTS: 1,
    CUSTOM_SENTENCES: 2,
    LISTEN_WORDS: 3,
    LISTEN_SENTENCES: 4,
    LANGUAGE_SCRIPT: 5,
    TASK: 6,
    IMPORT_DATA: 7,
    READING_TUTORIAL: 8,
    RESERVED2: 9,
  } as const;
  /* eslint-enable */

  const LESSON_TYPE_NAMES = {
    [LESSON_TYPES.TEXT]: 'Text Content',
    [LESSON_TYPES.WORD_TESTS]: 'Word Tests',
    [LESSON_TYPES.CUSTOM_SENTENCES]: 'Custom Sentences',
    [LESSON_TYPES.LISTEN_WORDS]: 'Listen Words',
    [LESSON_TYPES.LISTEN_SENTENCES]: 'Listen Sentences',
    [LESSON_TYPES.LANGUAGE_SCRIPT]: 'Language Script',
    [LESSON_TYPES.TASK]: 'Task',
    [LESSON_TYPES.IMPORT_DATA]: 'Import Data',
    [LESSON_TYPES.READING_TUTORIAL]: 'Reading Tutorial',
  };

  let lesson = {
    content: '',
    contentRef: '',
    duration: 0,
    lessonType: LESSON_TYPES.TEXT as LessonType,
    orderIndex: 0,
    title: '',
    videoUrl: ''
  };

  const selectedLanguage = languages.filter(el => el.iso === iso)[0].id;

  import('@/kit/svelteQuill').then((el: any) => {
    quill = el.quill
  })


  const setContent = (e: CustomEvent) => {
    lesson.content = e.detail.html
  }

  onMount(async () => {
    if (id !== 'new') {
      await loadLesson();
    }
  });

  const loadLesson = async () => {
    try {
      const data = await fetchData('modcourse/list', {
        id,
        idLanguage: selectedLanguage,
        idModule: moduleId
      });

      if (!data.lesson) {
        notifier.warning('Lesson not found');
        return;
      }

      const {
        title,
        content,
        content_ref: contentRef,
        video_url: videoUrl,
        duration,
        lesson_type: lessonType,
        order_index: orderIndex
      } = data.lesson;
      lesson = {
        content,
        contentRef,
        duration,
        lessonType,
        orderIndex,
        title,
        videoUrl
      };
      setTimeout(() => {
        const editorElement = document.querySelector('.ql-editor')
        if (editorElement) {
          editorElement.innerHTML = lesson.content
        }
      }, 200)
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to load lesson');
    }
  };

  const handleSubmit = async (event: Event) => {
    event.preventDefault();
    try {
      const method = id === 'new' ? 'saveLesson' : 'updateLesson';
      const data = {
        content: lesson.content || '',
        contentRef: lesson.contentRef || '',
        duration: parseInt(lesson.duration) || 0,
        id: id !== 'new' ? parseInt(id) : undefined,
        idModule: parseInt(moduleId),
        lessonType: lesson.lessonType,
        orderIndex: parseInt(lesson.orderIndex) || 0,
        title: lesson.title || '',
        videoUrl: lesson.videoUrl || ''
      }

      const response = await fetchData(`modcourse/${method}`, data);
      if (response.error) {
        notifier.warning(response.error);
      } else {
        notifier.success('Lesson saved successfully');
        navigate(`/${iso}/courses/${courseId}/modules/${moduleId}`)
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to save lesson');
    }
  };
</script>

<div class="lesson-management">
  <Breadcrumb
   segments={[
     { href: `/${iso}`, title: iso },
     { href: `/${iso}/courses`, title: 'Courses' },
     { href: `/${iso}/courses/${courseId}`, title: 'Course' },
     { href: `/${iso}/courses/${courseId}/modules/${moduleId}`, title: 'Module' },
     { title: id === 'new' ? 'Add New Lesson' : 'Edit Lesson' }
     ]}
  />
  <div class="header">
    <h1>{id === 'new' ? 'Add New Lesson' : 'Edit Lesson'}</h1>
  </div>

  <div class="content">
    <form class="lesson-info" on:submit={handleSubmit}>
      <div class="form-group">
        <label for="title">Title</label>
        <input id="title" required type="text" bind:value={lesson.title} name="title" />
      </div>

      <div class="form-group">
        <label for="lesson_type">Lesson Type</label>
        <select id="lesson_type" bind:value={lesson.lessonType} name="lesson_type">
          {#each Object.entries(LESSON_TYPE_NAMES) as [value, name]}
            <option value={parseInt(value)}>{name}</option>
          {/each}
        </select>
      </div>

      <div class="form-group">
        <label for="video_url">Video URL</label>
        <input id="video_url" type="url" bind:value={lesson.videoUrl} name="video_url" />
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="duration">Duration (minutes)</label>
          <input id="duration" type="number" bind:value={lesson.duration} name="duration" />
        </div>

        <div class="form-group">
          <label for="order_index">Order</label>
          <input id="order_index" type="number" bind:value={lesson.orderIndex} name="order_index" />
        </div>
      </div>

      <div class="form-group" class:-short={isShort}>
        <label for="content">Content <span role="button" tabindex="0" on:click={() => {isShort = !isShort}} on:keypress={() => {}} style:float='right'>{isShort ? '↔' : '🖼️'}</span></label>
        {#if quill}
          <div class='editor' on:text-change={setContent} use:quill={options}/>
        {/if}
      </div>

      {#if [LESSON_TYPES.LANGUAGE_SCRIPT, LESSON_TYPES.TASK, LESSON_TYPES.IMPORT_DATA].includes(lesson.lessonType)}
        <div class="form-group">
          <label for="content_ref">
            {#if lesson.lessonType === LESSON_TYPES.LANGUAGE_SCRIPT}
              Script ID
              {:else if lesson.lessonType === LESSON_TYPES.TASK}
              Task url (e.g. <em>/deu/task/read/create/1833</em>)
              {:else if lesson.lessonType === LESSON_TYPES.IMPORT_DATA}
              Import ID
            {/if}
          </label>
          <input id="content_ref" required type="text" bind:value={lesson.contentRef} name="content_ref" />
        </div>
      {/if}

      <div class="form-actions">
        <button class="primary" type="submit">{id === 'new' ? 'Create Lesson' : 'Update Lesson'}</button>
      </div>
    </form>
  </div>
</div>

<style lang="scss">
  .lesson-management {
    padding: 2rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .lesson-info {
    padding: 2rem;
    background: var(--Base-White);
    border-radius: 0.4rem;
  }

  .form-group {
    margin-bottom: 1.4rem;

    &.-short {
      max-width: 64rem;
    }

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    input[type="text"],
    input[type="number"],
    input[type="url"],
    select {
      width: 100%;
      padding: 0.5rem;
      border: solid var(--Gray-Light) 0.1rem;
      border-radius: 0.4rem;
    }
  }

  .form-actions {
    display: flex;
    gap: 1rem;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
</style>
