<script lang='ts'>
  import {poses} from '@/helpers/data'
  import {fetchData} from '@/helpers/fetchHelpers'
  import {getFormData} from '@/helpers/formHelpers'
  import {toInt} from '@/helpers/mix'
  import {notifier} from '@/helpers/notifier'

  import Overlay from '@/components/Overlay.svelte'

  document.title = 'WIFS populate'

  let pos = 0

  let batchesList: any[] = []
  let customForms: any = {}
  let unitWords: any[] = []
  let wordDetails: any = {}
  let currentWord: any = {}
  let myUnitWords: any = {}
  let numOfTemplates = 0
  let rule: any = {}

  let choosenCustomForm = ''
  let customFormText = ''

  let wordsFiltered: any
  let wordsStats: any = {}
  let wifTemplates: any[] = []
  let addedWifs: any[] = []

  $ : wordsFiltered = pos ? unitWords.filter(word => word.posId === pos) : unitWords
  $: if (pos || pos === 0) {
    let answered = 0
    for (const index in wordsFiltered) {
      const word = wordsFiltered[index]
      answered += (myUnitWords?.[word.id_word] > 0 || addedWifs?.[word.id_word] > 0) ? 1 : 0
    }
    wordsStats = {
      answered,
      pos: poses[pos].pos,
      total: wordsFiltered.length
    }
  } else {
    let answered = 0
    for (const index in wordsFiltered) {
      const word = wordsFiltered[index]
      answered += (myUnitWords?.[word.id_word] > 0 || addedWifs?.[word.id_word] > 0) ? 1 : 0
    }
    wordsStats = {
      answered,
      pos: poses[pos].pos,
      total: wordsFiltered.length
    }
  }

  fetchData('mod/wifMyBatches', {}).then((data) => {
    if (data.error) {
      notifier.error(data.error)
    } else {
      batchesList = data
    }
  })

  function loadBatchById(id: number) {
    fetchData('mod/wifUnitLoad', { id }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        unitWords = data.unitWords
        myUnitWords = data.myUnitWords
        addedWifs = data.wifs // Object.keys(data.wifs).filter(el=>data.wifs[el])
      }
    })
  }

  function wifLoadWordById(id: number) {
    fetchData('mod/wifLoadWordById', { id }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        // console.log(data)
        wordDetails = data
        wifTemplates = data.allWifTemplates
        currentWord = data.word
        rule = data.rule
        numOfTemplates = 0

        type wifType = {
          content: string;
          id_wif_template: number;
          wif: string;
          wifOrder: number;
          wif_order: number;
        }
        setTimeout(() => {
          for (let i = 0; i < wifTemplates.length; i++) {
            const uSelector = document.querySelector(`u[data-wif="${wifTemplates[i].content}"]`)
            const inputSelector = document.querySelector(`input[data-wif="${wifTemplates[i].content}"]`) as HTMLInputElement
            if (uSelector) {
              uSelector.innerHTML = ''
              // Just for testing, should be deleted after everything is working
              data.wifs.filter((wif: wifType) => toInt(wif.wifOrder) === 0 && wif.id_wif_template === wifTemplates[i].id).forEach((wif: wifType) => {
                uSelector.innerHTML += uSelector.innerHTML === '' ? wif.wif : ` / ${wif.wif}`
              })
            }

            data.unitForms.filter((wif: wifType) => wif.id_wif_template === wifTemplates[i].id).forEach((wif: wifType) => {
              if (wif.wif_order > 0) {
                if (!customForms[id]) {
                  customForms[id] = []
                }
                customForms[id].push([wifTemplates[i].content, wif.content, wif.wif_order])
                customForms = customForms
              }
            })
            if (inputSelector) {
              ++numOfTemplates
              let myValue = ''
              let publishedValue = ''
              let generatedValue = ''
              data.wifs.filter((wif: wifType) => wif.id_wif_template === wifTemplates[i].id).forEach((wif: wifType) => {
                publishedValue += myValue === '' ? wif.wif : ` / ${wif.wif}`
              })
              data.unitForms.filter((wif: wifType) => wif.id_wif_template === wifTemplates[i].id).forEach((wif: wifType) => {
                myValue += myValue === '' ? wif.content : ` / ${wif.content}`
              })
              data.generated.filter((wif: wifType) => wif.wif === wifTemplates[i].content).forEach((wif: wifType) => {
                generatedValue += wif.content
              })

              inputSelector.value = publishedValue || myValue || generatedValue
              inputSelector.dataset.original = generatedValue
              inputSelector.style.color = publishedValue ? 'green' : (myValue === '' ? 'red' : 'black')
              resizeInput(inputSelector)
            }
          }
        }, 200)
      }
    })
  }

  function submitWifs(e: any) {
    const formData = getFormData(e.target)
    fetchData('mod/wifUnitSave', {
      ...formData,
      languageId: currentWord.id_language,
      wordId: currentWord.id
    }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      }
      wordDetails = {}
      myUnitWords[data.wordId] = Object.keys(formData?.wif || {}).length + Object.keys(formData?.other || {}).length
    })
  }

  function submitCustomWifs(e: any) {
    e.preventDefault()
    const formData = getFormData(e.target)
    fetchData('mod/wifUnitSave', {
      ...formData,
      languageId: currentWord.id_language,
      wordId: currentWord.id
    }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      }
      wordDetails = {}
      const num = Object.keys(formData?.wif || {}).length + Object.keys(formData?.other || {}).length
      myUnitWords[data.wordId] += num
    })
  }

  const loadGenerated = () => {
    const els: any = document.querySelectorAll('input[data-wif]')
    if (els) {
      for (let i = 0; i < els.length; i++) {
        const el = els[i]
        if (el) {
          el.value = el.dataset.original || ''
          el.style.color = 'red'
        }
      }
    }
  }

  const closeModal = () => {
    wordDetails = {}
  }

  const resizeInput = (el: HTMLInputElement) => {
    el.style.width = '0'
    el.style.width = (el.scrollWidth + 2) + 'px'
  }

  const resizeInputs = (e: any) => {
    if (e.target.tagName === 'INPUT') {
      resizeInput(e.target)
    }
  }

  const findMaxWifOrder = (template: string) => {
    const orders = customForms[currentWord.id].filter((el: any[]) => el[0] === template).map((el: any[]) => el[2])
    for (let i = 1; i < 20; i++) {
      if (!orders.includes(i)) return i
    }
    return 1
  }

  const saveCustomForm = () => {
    if (!customForms[currentWord.id]) {
      customForms[currentWord.id] = []
    }
    const wifOrder = findMaxWifOrder(choosenCustomForm)
    customForms[currentWord.id].push([choosenCustomForm, customFormText, wifOrder])
    customForms = customForms
  }

  const refresh = () => {
    wifLoadWordById(currentWord.id)
  }
  const removeSpecialSyntax = (str: string) => {
    return str.replace(/@@\.([a-z0-9A-Z]+)/g, (match, group) => group)
  }
</script>
<div>
  <div class='custom-select'>
    <select bind:value={pos} name=''>
      <option value=''>Select pos</option>
      {#each poses.filter(el => el.id > 0) as pos}
        <option value={pos.id}>{pos.pos}</option>
      {/each}
    </select>
  </div>

  <table>
    <caption>Batches</caption>
    {#each batchesList as batch}
      <tr class='singleBatch' on:click={() => loadBatchById(batch.id)}>
        <td>{batch.id}</td>
        <td>{batch.size}</td>
        <td>{batch.active}</td>
        <td>{batch.language}</td>
        <td>{batch.created_at}</td>
      </tr>
    {/each}
  </table>

  {#if wordDetails.htmlTemplate}
    <Overlay hidden={!wordDetails.htmlTemplate} onClick={closeModal}>
      {#if wordDetails.htmlTemplate}
        <div class='overlay-content wif-table-overlay'>

          <form
            action=''
            role='presentation'
            on:submit|preventDefault={submitWifs}
            on:keyup={resizeInputs}>
            <p>Used rule: {rule.id} {rule.name}</p>
            <!-- <pre>{JSON.stringify(wordDetails, null, 2)}</pre> -->
            {@html removeSpecialSyntax(wordDetails.htmlTemplate)}
            {#if numOfTemplates}
              <input type='submit' value='Save' />
            {:else}<p>No inflected forms found</p>
            {/if}
            <input type='button' value='Load generated' on:click={loadGenerated} />
            <input title='Used when you want to load rules while writing' type='button' value='Refresh' on:click={refresh} />
          </form>
          <!-- <input name="wif[verb_subjunctive_present_thirdperson_pluralø0]"
        data-wif="verb_subjunctive_present_thirdperson_plural" data-original="seien" style="color: red; width: 131px;"> -->
          <form action='' on:submit={submitCustomWifs}>
            Custom forms:

            <div class='custom-select'>
              <select id='other' bind:value={choosenCustomForm} name='other'>
                <option value=''>Select</option>
                {#each wifTemplates as template}
                  <option value={template.content}>{template.content.split('_').slice(1).join(' ')}</option>
                {/each}
              </select>
            </div>

            {#if choosenCustomForm}
              <input type='text' bind:value={customFormText} />
              <input type='button' value='Save form' on:click={saveCustomForm} />
            {/if}
            {#each (customForms[currentWord.id] || []) as form, k}
              <div>{form[0]}
                <input
                  data-wif-order={form[2]}
                  type='text'
                  value={form[1]}
                  name='wif[{form[0]}ø{form[2]}]'
                />
                {form[2]}
              </div>
            {/each}
            {#if customForms[currentWord.id]?.length}
              <input type='submit' value='Save custom forms' />
            {/if}
          </form>
        </div>
      {/if}
    </Overlay>
  {/if}

  {#if unitWords.length}
    <table class='words-table'>
      <caption>Words</caption>
      {#if wordsStats.total}
        Populated: {wordsStats.answered} / {wordsStats.total} {pos ? ` - ${wordsStats.pos}` : ''}
      {/if}

      {#each wordsFiltered as word}
        <tr class='singleWord' on:click={() => wifLoadWordById(word.id_word)}>
          <td id='word_{word.id_word}'>{myUnitWords?.[word.id_word] || addedWifs?.[word.id_word] || ''}</td>
          <td>{word.id_word}</td>
          <td>{word.word}</td>
          <td>{word.pos}</td>
          <td>{word.definition}</td>
        </tr>
      {/each}
    </table>
  {/if}
</div>

<style lang='scss'>
  /* stylelint-disable rscss/class-format,rscss/no-descendant-combinator */
  :global(u) {
    text-decoration: none;
  }

  .wif-table-overlay {
    input,
    select {
      min-width: 7.2rem;
      padding: 0.4rem;
    }

    input[type="submit"],
    input[type="button"] {
      min-width: 7.2rem;
    }
  }

  .singleBatch,
  .singleWord {
    td {
      min-width: 3.6rem;
      padding: 0.4rem;
      border: solid var(--Gray-Light) 0.1rem;
      cursor: pointer;
    }

    &:hover {
      td {
        background: var(--Gray-Light);
      }
    }
  }
</style>
