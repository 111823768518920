<script lang='ts'>
  type CondF = {
    c: string,
    error: string[],
    f: string,
    o: string,
    params: string[],
    t: string
  }

  type Cond = {
    c: string, code: string,
    end: string,
    error: string[],
    f: string,
    negated: string,
    start: string,
    var?: string }
  type Trans = {
    code: {
      c: string,
      error: string[],
      f: string,
      o: string,
      params: string[],
      t: string
    },
    last: boolean }
  type Rule = {
    c: Cond[],
    t: Trans[]
  }

  export let code = ''
  let rules: Rule[] = []
  const allowedCond = 'end|start|contains|syl|list|cond|prop|dup'.split('|')
  const allowedTransformation = 'plus|minus|pre|rep|re|ga|go|become|empty|taga|cut'.split('|')

  export const highlight = (html: string) => html.split('#').map(extract)

  $: if (code) {
    rules = highlight(code)
  }

  const extract = (rule: string) => {
    const [condPart, transformation] = rule.split('|')
    return {
      c: extractConditions(condPart),
      t: extractTransformation(transformation)
    }
  }
  const extractConditions = (condition: string) => {
    const conditions = condition.split('&&')
    const result: any[] = []

    conditions.forEach((c: string) => {
      const ret: Cond = { c, code: '', end: '', error: [], f: '', negated: '', start: '' }
      const cond = c.trim()
      let start = cond[0]
      let negated = false
      if (cond === '') {
        return ret
      }
      if (start === '!') {
        ret.negated = '!'
        start = cond[1]
        negated = true
      }
      if (start !== '[') {
        ret.start += `${cond.charAt(0)}`
        ret.error.push('Missing open [')
      } else {
        ret.start += '['
      }
      const end = cond[cond.length - 1]
      if (end !== ']') {
        ret.end = `${end}`
        if (ret.error.length === 0) ret.error.push('Missing closing ]')
      } else {
        ret.end = ']'
      }
      const code = cond.slice(1, cond.length - 1)
      const space = code.indexOf(' ')
      ret.f = space > -1 ? code.slice(negated ? 1 : 0, space) : code
      if (ret.f.includes('=')) {
        const tmp = ret.f.split('=')
        ret.f = tmp[1]
        ret.var = tmp[0]
      }
      if (!allowedCond.includes(ret.f)) {
        if (ret.error.length === 0) ret.error.push(`Unknown cond <b>${ret.f}</b>`)
      }
      ret.code = space > -1 ? code.slice(space + 1) : ''
      // todo - here we can add other cond validations
      if (ret.f === 'cond') {
        if (ret.code.includes(' ')) {
          ret.error.push('cond rule cannot contain spaces')
        } else if (ret.code.split(',').length !== 2) {
          ret.error.push('cond rule must have format: 192,CONDNAME')
        }
      }
      result.push(ret)
    })
    return result
  }
  const extractTransformation = (transformation: string) => {
    const ret: any[] = []
    const splitted = transformation?.split(';') || []
    splitted.forEach((f, ind) => {
      ret.push({ code: extractF(f), last: typeof splitted[ind + 1] !== 'undefined' })
    })
    return ret
  }

  const extractF = (t: string) => {
    let ret: CondF = { c: ' ', error: [], f: ' ', o: ' ', params: [], t }

    const f = t.trim()
    if (f === '') {
      return ret
    }
    const parts = f.split('(')
    if (parts.length === 1) {
      ret = {
        c: '',
        error: [`Missing open <b>(</b> in function <b>${ret.f}</b>`],
        f: parts[0],
        o: parts[0].slice(-1),
        params: [],
        t
      }
    } else {
      ret.f = parts[0]
      ret.o = '('
      ret.params = parts[1].slice(0, -1).split(',')
      ret.params.forEach((p) => {
        if (p.includes('(') || p.includes('(') || p.includes('#') || p.includes('|')) {
          ret.error.push(`Mailformed params <b>${p}</b>`) // todo - not fixed
        }
      })

      if (!allowedTransformation.includes(ret.f)) {
        ret.error.push(`Unknown transformation <b>${ret.f}</b>`)
      }
      ret.c = parts[1]?.slice(-1) || ''
      if (ret.c !== ')') {
        if (ret.error.length === 0) ret.error.push(`Missing closing <b>)</b> after <b>${ret.f}(${ret.params.join(',')}</b>`)
      }
    }

    if (ret.f === 'go') {
      if (ret.params.length !== 2) {
        ret.error.push('go rule must have format: 192,923')
      }
    }
    if (ret.f === 'cut') {
      if (!/^\d+$/.test(ret.params[0])) {
        ret.error.push('Param must be a number')
      }
    }

    return ret
  }

</script>
{#if rules.length}
  {#each rules as rule, k}
    <div class='code-line'><b class='ln'>{k + 1}</b>
      {#each rule.c as cond, cIndex}
        {#if cond.error?.length}
          {cond.c}
        {:else}
          <!-- eslint-disable-next-line max-len -->
          <b class='ne'>{cond.negated}</b><b class='start'>{cond.start}</b>{#if cond.var}<b class='var'>{cond.var}</b>={/if}<b class='f'>{cond.f}</b> {@html cond.code}<b class='end'>{cond.end}</b>{#if cIndex < rule.c.length - 1}<span class='and'> && </span>{/if}{/if}{/each}<b class='s1'>|</b>{#each rule.t as t}{#if t.code?.error?.length}{t.code.t}
      {:else}
        <!-- eslint-disable-next-line max-len -->
        {#if t.code.f}<b class='tf'>{t.code.f}</b>{t.code.o}{#each t.code.params as param, k}<b class='tp'>{param}</b>{t.code.params[k + 1] ? ',' : ''}{/each}{t.code.c}{#if t.last};{/if}{/if}{/if}{/each}<b class='error'>{@html (rule.c.map(cond => cond.error) || []).join(' ')}</b><b class='errorTrans'>{@html (rule.t.map(el => el.code.error) || []).join(' ')}</b>{#if k < rules.length - 1}<b class='s0'>#</b>{/if}
    </div>
  {/each}
{/if}
<style lang='scss'>
  /**
  ln  - line number
  s0, s1 - separators # and |
  f   - condition function
  tp  - parameter
  tf  - transformation function
  start,end - [ and ]
   */

  .code-line {
    position: relative;
    padding-left: 1rem;

    > .ln {
      position: absolute;
      top: 0.2rem;
      left: -3.2rem;
      display: inline-block;
      min-width: 3.2rem;
      margin-right: 0.5em;
      font-weight: normal;
      font-size: 0.8em;
      text-align: right;
      color: var(--Gray-Med);
    }

    > b {
      display: inline-block;
      font-weight: normal;
    }

    > .ne {
      font-weight: bold;
      font-size: 1.2em;
      color: var(--Warning-Medium);
    }

    > .error {
      color: var(--Error-Medium);
    }

    > .errorTrans {
      color: var(--Rose-Medium);
    }

    > .start,
    > .end {
      color: var(--Success-Dark);
    }

    > .s0 {
      color: var(--Gray-Med);
    }

    > .var {
      color: var(--Rose-Dark);
    }

    > .s1 {
      color: var(--Success-Light);
    }

    > .f {
      color: var(--Primary-Medium);
    }

    > .tp {
      white-space: pre;
      color: var(--Yellow-Medium);
    }

    > .tf {
      color: var(--Rose-Lighter);
    }

    > .and {
      display: inline-block;
      color: var(--Rose-Dark);
    }
  }
</style>
