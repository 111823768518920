<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import { capitalize, uniqObjects } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  const dispatch = createEventDispatcher()

  export let columns: any[] = []
  const activeFilters: any[][] = [['', '=', '']]
  const selected: any = {}
  const operations: any = {}
  const values: any = {}

  const operators = [
    '=', '<', '>', '<=', '>=', '!=', 'IN', 'CONTAINS', 'STARTS WITH', 'ENDS WITH', 'NOT CONTAINS', 'NOT STARTS WITH', 'NOT ENDS WITH', 'NOT IN'
  ]

  const newFilter = (i: any, e: any, index: any) => {
    activeFilters[i][index] = e.target.value
    const emptyFilters = activeFilters.filter(f => f[0] === '')
    if (emptyFilters.length === 0) {
      activeFilters.push(['', '=', ''])
    }
  }

  function applyFilter() {
    const active = activeFilters.filter(f => f[0] !== '')
    if (active.length === 0) {
      notifier.error('Please select at least one filter')
    }
    dispatch('change', uniqObjects(active))
  }

</script>
<div class='filter-wrapper'>
  {#if columns.length > 0}
    {#each activeFilters as activeFilter, i}
      <div>

        <div class='custom-select'>
          <select bind:this={selected[i]} on:change={(e) => { newFilter(i, e, 0) }}>
            <option value=''>Choose</option>
            {#each columns as column}
              <option value={column}>{column}</option>
            {/each}
          </select>

        </div>
        <div class='custom-select'>
          <select bind:this={operations[i]} class:-hidden={selected?.[i]?.value === ''} on:change={(e) => { newFilter(i, e, 1) }}>
            {#each operators as row}
              <option value={row}>{capitalize(row.toLowerCase())}</option>
            {/each}
          </select>
        </div>
        <input bind:this={values[i]} class:-hidden={selected?.[i]?.value === ''} type='text' on:change={(e) => { newFilter(i, e, 2) }} />
      </div>
    {/each}
    <button type='button' on:click={() => applyFilter()}>Apply</button>
    <button
      class='reset'
      type='button'
      on:click={() => {
        selected[0].value = ''
        activeFilters.length = 1
      }}>Reset
    </button>
  {/if}
</div>
<style lang='scss'>
  .filter-wrapper {
    float: right;
    padding: 0.4rem;
    border: solid var(--Gray-Medium) 0.1rem;

    > div {
      display: flex;
      > input.-hidden {
        display: none;
      }
    }


    > .reset {
      padding: 0.2rem;
      font-size: 0.8em;
      background: var(--Gray-Medium);
    }
  }

  .custom-select > select.-hidden {
    display: none;
  }
</style>
