<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { notifier } from '@/helpers/notifier'

  import ApproveTranslationDoc from '@/components/docs/lesson/ApproveTranslationDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'Approve translation'

  export let iso = ''
  let page = parseInt(getParameterByName('page')) || 1
  let sentences: any[] = []
  let pagesCount = 0
  let lessons: any[] = []
  let translations: any = {}
  let lessonChooser: any
  let lessonsOrder: any[] = []

  const loadPage = (pg: any, markLesson = true) => {
    page = pg
    fetchData('mod/lessonTranslateApprove', {
      page: pg
    }).then((data) => {
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + pg)
      sentences = data.sentences
      pagesCount = data.pagesCount
      translations = data.translations
      lessonsOrder = data.lessonsOrder

      if (data.lessons) {
        lessons = Object.values(data.lessons.sort((a: any, b: any) => b.name < a.name))
      }
      if (markLesson) {
        setTimeout(() => {
          if (lessonChooser) lessonChooser.value = data.lesson
        }, 200)
      }
    })
  }
  loadPage(page)
  const vote = (id: any, val: any, e: any) => {
    e.target.disabled = true
    fetchData('mod/lessonTranslateApproveSave', {
      id,
      vote: val
    }).then(() => {
      notifier.info('Saved')
      const up = e.target.classList.contains('up')
      e.target.parentNode.parentNode.querySelector('button.' + (up ? 'down' : 'up')).disabled = false
    })
  }

  const changeLesson = () => {
    const index = lessonsOrder.indexOf(parseInt(lessonChooser.value)) + 1
    if (index > 0) loadPage(index, false)
    else {
      sentences = []
    }
  }
</script>

<div>
  {#if iso === 'eng'}
    This page is available just for languages other than English
  {:else if lessons.length}
    <div class='custom-select'>
      <select bind:this={lessonChooser} on:change={changeLesson} name='lessons'>
        <option value=''>Choose lesson</option>
        {#each lessons as lesson (lesson.id)}
          <option value={lesson.id}>{lesson.name}</option>
        {/each}
      </select>
    </div>
    {#if sentences.length}
      <div class='prev-next-flex'>
        {#if page > 1}
          <button type='button' on:click={() => loadPage(page - 1)}>Prev</button>
        {/if}
        <div>
          <table>
            <tr>
              <th>Original</th>
              <th>Translation</th>
            </tr>
            {#each sentences as sentence}
              <tr>
                <td class='td'>
                  {#if sentence.comments}
                    <div class='word-comment'>
                      {@html sentence.formatted_comments}
                    </div>
                  {:else}{sentence.content}{/if}
                  {#if sentence.sentence_comment}
                    <div>Sentence comment: <span class='sentence-comment'>{sentence.sentence_comment}</span></div>
                  {/if}
                </td>
                <td class='td'>
                  {#if translations[sentence.id]}
                    {#each translations[sentence.id] as trans}
                      <table>
                        <tr>
                          <td>{trans.content}</td>
                          <td>{trans.num}</td>
                          <td>
                            <button class='up' disabled={trans.myPoints > 0} type='button' on:click={(e) => vote(trans.id, 1, e)}>Approve</button>
                          </td>
                          <td>
                            <button class='down' disabled={trans.myPoints < 0} type='button' on:click={(e) => vote(trans.id, -1, e)}>Reject</button>
                          </td>
                        </tr>
                      </table>
                    {/each}
                  {/if}
                </td>
              </tr>
            {/each}
          </table>
        </div>

        {#if page < pagesCount}
          <button type='button' on:click={() => loadPage(page + 1)}>Next</button>
        {/if}
      </div>
    {:else}Choose lesson
    {/if}
  {:else}No lessons for translation in this language
  {/if}
</div>
<Documentation>
  <ApproveTranslationDoc />
</Documentation>
<style lang='scss'>
  td {
    padding: 0.2rem 1rem;
    border-top: solid var(--Gray-Medium) 0.1rem;
  }

  /* stylelint-disable-next-line rscss/class-format */
  .up,
  .down {
    &:disabled {
      background: var(--Primary-Ligth);
    }
  }

  .sentence-comment {
    color: var(--Error-Medium);
  }

  .word-comment {
    color: var(--Primary-Medium);
  }
</style>
